import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createMuiTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: "#F4F4F4"
    },
    primary: {
      main: "#397ABF"
    },
    secondary: {
      main: "#8BB2DC"
    },
    error: {
      main: "#D9534F"
    },
    warning: {
      main: "#C09853"
    },
    text: {
      // primary: colors.blueGrey[900],
      // secondary: colors.blueGrey[600]
    }
  },
  shadows: shadows,
  typography
});

export default theme;
