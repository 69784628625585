import { IJwt } from "src/apps/modules/auth/types";
import { STORAGE_AUTH_NAME } from "src/constants";

type FetchApiWithoutBodyOptions = {
  method: "GET";
  headers?: HeadersInit;
  noAuth?: boolean;
  noContentType?: boolean;
};

type FetchApiWithBodyOptions = {
  method: "POST" | "PUT" | "DELETE";
  headers?: HeadersInit;
  body?: BodyInit;
  noAuth?: boolean;
  noContentType?: boolean;
};

type FetchApiOptions = FetchApiWithoutBodyOptions | FetchApiWithBodyOptions;

const apiEndPoint = process.env.API_URL;
const urlBuilder = (url: string) => `${apiEndPoint}/api/admin/${url}`;

const isHttpStatusSuccess = (httpStatus: number) =>
  httpStatus >= 200 && httpStatus < 300;

const handleHttpError = async (
  response: Response,
  httpStatus: number
): Promise<HttpError> => {
  const contentType = response.headers.get("content-type");
  if (contentType?.indexOf("application/json") !== -1) {
    const json = await response.json();
    if (json.errors) {
      return new HttpError(json.errors, httpStatus);
    } else if (json.error) {
      return new HttpError([json.error], httpStatus);
    } else {
      return new HttpError(
        [`Unknown reason: ${JSON.stringify(json)}`],
        httpStatus
      );
    }
  } else {
    return new HttpError(["Could not connect to server"], httpStatus);
  }
};

const handleNoResponse = (): HttpError => {
  return new HttpError(["ไม่สามารถเชื่อมต่อกับ Server ได้"], 400);
};

const getAuthInfoFromLocalStorage = () => {
  try {
    const authInfoJson = localStorage.getItem(STORAGE_AUTH_NAME);
    return authInfoJson ? (JSON.parse(authInfoJson) as IJwt) : null;
  } catch (e) {
    return null;
  }
};

export class HttpError extends Error {
  messages: Array<string> = [];
  httpStatus = 200;
  constructor(messages: string[], httpStatus: number) {
    super(messages[0]);
    this.name = this.constructor.name;
    this.messages = messages;
    this.httpStatus = httpStatus;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(messages[0]).stack;
    }
  }
}

const fetchApi = async (url: string, options: FetchApiOptions) => {
  const { noAuth, noContentType } = options;

  const contentType = noContentType
    ? undefined
    : {
        "Content-Type": "application/json"
      };

  const authHeader = noAuth
    ? null
    : { Authorization: `Bearer ${getAuthInfoFromLocalStorage()?.token}` };

  const headers = {
    ...contentType,
    ...authHeader,
    ...options.headers
  };

  const fetchOptions = (options as FetchApiWithBodyOptions).body
    ? {
        method: options.method,
        headers: headers,
        body: (options as FetchApiWithBodyOptions).body
      }
    : {
        method: options.method,
        headers: headers
      };

  try {
    return await fetch(urlBuilder(url), fetchOptions);
  } catch (e) {
    throw handleNoResponse();
  }
};

export const fetchJson = async <T extends unknown>(
  url: string,
  options: FetchApiOptions
): Promise<T> => {
  try {
    const response = await fetchApi(url, options);

    if (response) {
      const httpStatus = response.status;
      if (isHttpStatusSuccess(httpStatus)) {
        return response.json();
      } else {
        throw await handleHttpError(response, httpStatus);
      }
    }
  } catch (e) {
    if (e instanceof HttpError) throw e;
  }
  throw handleNoResponse();
};

export const fetchResponse = async (
  url: string,
  options: FetchApiOptions
): Promise<Response> => {
  const response = await fetchApi(url, options);

  if (response) {
    const httpStatus = response.status;
    if (isHttpStatusSuccess(httpStatus)) {
      return response;
    } else {
      throw await handleHttpError(response, httpStatus);
    }
  }
  throw handleNoResponse();
};
